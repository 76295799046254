import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import Messenger from '../components/messenger'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import musclers1 from '../assets/images/trig_014.webp'
import pic02 from '../assets/images/trig_005.webp'
import pic03 from '../assets/images/trig_010.webp'
import pic04 from '../assets/images/trig_013.webp'
import pic05 from '../assets/images/trig_007.webp'
import pic09 from '../assets/images/trig_002.webp'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="三健客3musclers健身俱樂部｜您最專業的健身房課程"
          meta={[
            {
              name: 'description',
              content:
                '三健客3musclers健身俱樂部在台北內湖、板橋、永和皆有健身房分館，這裡聚集了一群喜愛健身的夥伴一起運動，我們有推薦的一對一教練課程、團體課程、研習課程等多元的健身課程可以選擇，三健客漸進式的專業教學，為您打造最適合您的健身計劃。',
            },
            {
              name: 'keywords',
              content:
                '三健客,健身房,健身房推薦,健身俱樂部,健身房課程,私人健身房,教練課程,健身房教練,團體課程',
            },
            {
              property: 'og:title',
              content: '三健客 3musclers｜專業健身俱樂部',
            },
            {
              property: 'og:site_name',
              content: '三健客 3musclers｜專業健身俱樂部',
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:url',
              content: 'https://www.3musclers.com',
            },
            {
              property: 'og:image:width',
              content: '1200',
            },
            {
              property: 'og:image:height',
              content: '960',
            },
            {
              property: 'og:image',
              content:
                'https://user-images.githubusercontent.com/53250201/84200644-bfc1dd80-aad9-11ea-9f39-676918d12f40.jpg',
            },
            {
              property: 'og:description',
              content:
                '如同三劍客中主角聚集了一群志同道合的夥伴一起面對強權，也是由一群健身訓練者聚集而成，在健身這個自我修煉的路途中互相陪伴。',
            },
            {
              name: 'google-site-verification',
              content: 'JCdlUz7nbfqOB_kx56U2CagczfsV0Iaaz0LoBxMxDDI',
            },
          ]}
        >
          <link rel="canonical" href="https://www.3musclers.com" />
        </Helmet>

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic05})` }}>
              <header className="major">
                <h2>關於我們</h2>
                <p>about us</p>
              </header>
              <Link
                to="/aboutUs"
                className="link primary"
                onClick={e => {
                  // e.preventDefault()
                  trackCustomEvent({
                    category: '關於我們',
                    action: 'Click',
                    label: 'Gatsby Google Analytics',
                  })
                }}
              ></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <header className="major">
                <h2>最新消息</h2>
                <p>news</p>
              </header>
              <Link
                to="/news"
                className="link primary"
                onClick={e => {
                  // e.preventDefault()
                  trackCustomEvent({
                    category: '最新消息',
                    action: 'Click',
                    label: 'Gatsby Google Analytics',
                  })
                }}
              ></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h2>場館介紹</h2>
                <p>Venue introduction</p>
              </header>
              <Link
                to="/serviceItem"
                className="link primary"
                onClick={e => {
                  // e.preventDefault()
                  trackCustomEvent({
                    category: '場館介紹',
                    action: 'Click',
                    label: 'Gatsby Google Analytics',
                  })
                }}
              ></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
              <header className="major">
                <h2>課程介紹</h2>
                <p>course introduction</p>
              </header>
              <Link
                to="/lesson"
                className="link primary"
                onClick={e => {
                  // e.preventDefault()
                  trackCustomEvent({
                    category: '課程介紹',
                    action: 'Click',
                    label: 'Gatsby Google Analytics',
                  })
                }}
              ></Link>
            </article>
            <article style={{ backgroundImage: `url(${musclers1})` }}>
              <header className="major">
                <h2>會員成果</h2>
                <p>Member Achievements</p>
              </header>
              <Link
                to="/404"
                className="link primary"
                onClick={e => {
                  // e.preventDefault()
                  trackCustomEvent({
                    category: '會員成果',
                    action: 'Click',
                    label: 'Gatsby Google Analytics',
                  })
                }}
              ></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic09})` }}>
              <header className="major">
                <h2>常見問題</h2>
                <p>question & answer</p>
              </header>
              <Link
                to="/question"
                className="link primary"
                onClick={e => {
                  // e.preventDefault()
                  trackCustomEvent({
                    category: '常見問題',
                    action: 'Click',
                    label: 'Gatsby Google Analytics',
                  })
                }}
              ></Link>
            </article>
          </section>
          {/* <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Massa libero</h2>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus pharetra. Pellentesque condimentum sem. In efficitur ligula tate urna. Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus amet pharetra et feugiat tempus.</p>
                            <ul className="actions">
                                <li><Link to="/landing" className="button next">Get Started</Link></li>
                            </ul>
                        </div>
                    </section> */}
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
