import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>三健客 3musclers</h1>
            </header>
            <div className="content">
                <p>
					 如同小說『三劍客』一般，你們都是我們的達太安，三健客會是您最好的健身夥伴！</p>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly ">往下瀏覽</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
